import React from 'react';
import './Map.css';
import McsInfo from './Mcsinfo';
import DiagvarInfo from './Diagvarinfo';
import MeteostationInfo from './Meteostationinfo';
import TornadoInfo from './Tornadoinfo';
import WindthrowInfo from './WindthrowInfo';
import { Button } from 'react-bootstrap';
import {connect} from 'react-redux';
import {togglemsdata, visiblemsdata} from './reduxToolkit/Reducers';

class EventInfo extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          moreinfo: false,
          infoid: null,
        }
    }

    moreInfoFunc = (i, tbname) => {
        this.setState({
            moreinfo: tbname,
            infoid: i
        });
    }

    eventInfo = (payload) => {
        this.setState({
            moreinfo: payload
        });
        this.props.togglemsdata();
        this.props.visiblemsdata(false);
    }

    render(){
        let repData = this.props.allevinfo;
        let mcslink;
        let diagvar;
        let mstation;
        let tornadolink;
        let windthrowlink;
        let eswdid;
        if(repData.associated_mcs_reports!==null){
            mcslink = <tr>
                        <td>{this.props.lang === 'ru' ? "Информация о мезомасштабной конвективной системе (МКС)" : "Associated mesoscale convective system (MCS)"}</td>
                        <td><Button size="sm" variant="warning" onClick={() => {this.moreInfoFunc(repData.associated_mcs_reports, "mcs")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>;
        }
        if(repData.diagnostic_variables_id!==null){
            diagvar = <tr>
                        <td>{this.props.lang === 'ru' ? "Диагностические переменные" : "Diagnostic variables"}</td>
                        <td><Button size="sm" variant="warning" onClick={() => {this.moreInfoFunc(repData.diagnostic_variables_id, "diagvar")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>;
        }
        if(repData.weather_station_id!==null){
            mstation = <tr>
                        <td>{this.props.lang === 'ru' ? "Ближайшая метестанция" : "Nearest wheather station"}</td>
                        <td>{this.props.lang === 'ru' ? repData.weather_station_name_ru : repData.weather_station_name_en}<Button size="sm" variant="warning" style={{marginLeft: '10px'}} onClick={() => {this.moreInfoFunc(repData.weather_station_id, "ms")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>;
        }
        if(repData.id_tornado_database!==null){
            tornadolink = <tr>
                        <td>{this.props.lang === 'ru' ? "Смерч" : "Tornado"}</td>
                        <td><Button size="sm" variant="warning" onClick={() => {this.moreInfoFunc(repData.id_tornado_database, "tornado")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>;
        }
        if(repData.id_windthrow_database!==null){
            windthrowlink = <tr>
                        <td>{this.props.lang === 'ru' ? "Ветровал" : "Windthrow"}</td>
                        <td><Button size="sm" variant="warning" onClick={() => {this.moreInfoFunc(repData.id_windthrow_database, "windthrow")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>;
        }
        if(repData.eswd_id==="Y"){
            eswdid = this.props.lang === 'ru' ? "Да" : "Yes";
        }else{
            eswdid = this.props.lang === 'ru' ? "Нет" : "No";
        }
        switch(this.state.moreinfo){
            case false:
                return(
                    <tbody>
                        <tr><td colSpan="2" style={{padding: '0', borderTopColor: 'white', borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'white'}}><Button onClick={() => this.props.closeeventinfo(false)}>{this.props.lang === 'ru' ? "Назад" : "Back"}</Button></td></tr>
                        <tr><td colSpan="2"><b>{this.props.lang === 'ru' ? "Подробная информация о явлении:" : "Detailed information about event:"}</b></td></tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Тип явления" : "Event type"}</td>
                            <td>{this.props.lang === 'ru' ? repData.event_type_ru : repData.event_type}</td>
                        </tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Дата" : "Date"}</td>
                            <td>{repData.day}.{repData.month}.{repData.year}</td>
                        </tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Качество данных" : "Data quality"}</td>
                            <td>{this.props.lang === 'ru' ? repData.data_quality_ru : repData.data_quality}</td>
                        </tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Субъект РФ" : "Region"}</td>
                            <td>{this.props.lang === 'ru' ? repData.region_ru : repData.region_en}</td>
                        </tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Район" : "District"}</td>
                            <td>{this.props.lang === 'ru' ? repData.district_ru : repData.district_en}</td>
                        </tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Населенный пункт" : "Settlement"}</td>
                            <td>{this.props.lang === 'ru' ? repData.settlement_name_ru : repData.settlement_name_en}</td>
                        </tr>
                        {repData.location_description_rus !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Описание местоположения" : "Location description"}</td>
                            <td>{this.props.lang === 'ru' ? repData.location_description_ru : repData.location_description_en}</td>
                        </tr>}
                        {repData.time_start_utc !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Время начала ВСВ" : "Time start UTC"}</td>
                            <td>{repData.time_start_utc}</td>
                        </tr>}
                        {repData.time_end_utc !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Время окончания ВСВ" : "Time end UTC"}</td>
                            <td>{repData.time_end_utc}</td>
                        </tr>}
                        {repData.time_accuracy !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Точность определения времени" : "Time accuracy"}</td>
                            <td>{this.props.lang === 'ru' ? repData.time_accuracy_ru : repData.time_accuracy}</td>
                        </tr>}
                        {repData.intensity !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Интенсивность" : "Intensity"}</td>
                            <td>{this.props.lang === 'ru' ? repData.intensity + ' ' + repData.unit_of_intensity_ru : repData.intensity + ' ' + repData.unit_of_intensity}</td>
                        </tr>}
                        {repData.duration !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Продолжительность" : "Duration"}</td>
                            <td>{this.props.lang === 'ru' ? repData.duration + ' ' + repData.unit_of_duration_ru : repData.duration + ' ' + repData.unit_of_duration}</td>
                        </tr>}
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Тип регистрации" : "Report type"}</td>
                            <td>{this.props.lang === 'ru' ? repData.report_type_ru : repData.report_type}</td>
                        </tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Тип источника данных" : "Source report"}</td>
                            <td>{this.props.lang === 'ru' ? repData.source_rep_ru : repData.source_rep}</td>
                        </tr>
                        {repData.event_description_rus !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Описание явления" : "Event description"}</td>
                            <td>{this.props.lang === 'ru' ? repData.event_description_rus : repData.event_description_en}</td>
                        </tr>}
                        {repData.damage_description_rus !==null &&<tr>
                            <td>{this.props.lang === 'ru' ? "Описание ущерба" : "Damage description"}</td>
                            <td>{this.props.lang === 'ru' ? repData.damage_description_rus : repData.damage_description_en}</td>
                        </tr>}
                        {repData.dead !==null &&<tr>
                            <td>{this.props.lang === 'ru' ? "Погибшие, чел." : "Dead"}</td>
                            <td>{repData.dead}</td>
                        </tr>}
                        {repData.injured !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Пострадавшие, чел." : "Injured"}</td>
                            <td>{repData.injured}</td>
                        </tr>}
                        {repData.financial_losses_mln_rub !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Экономический ущерб, млн руб." : "Financial losses, mln rub"}</td>
                            <td>{repData.financial_losses_mln_rub}</td>
                        </tr>}
                        {repData.external_url !==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Внешние ссылки" : "External URLs"}</td>
                            <td>{repData.external_url!==null &&<a href={repData.external_url} target="_blank" rel="noopener noreferrer">{this.props.lang === 'ru' ? "ссылка 1" : "link 1"}</a>} 
                            {repData.external_url_2!==null && <p><a href={repData.external_url_2} target="_blank" rel="noopener noreferrer">{this.props.lang === 'ru' ? "ссылка 2" : "link 2"}</a></p>}</td>
                        </tr>}
                        {repData.video_url!==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Видеоматериалы" : "Video URL"}</td>
                            <td><a href={repData.video_url} target="_blank" rel="noopener noreferrer">{this.props.lang === 'ru' ? "ссылка" : "video"}</a></td>
                        </tr>}
                        {repData.comment_ru!==null && <tr>
                            <td>{this.props.lang === 'ru' ? "Комментарий" : "Comment"}</td>
                            <td>{this.props.lang === 'ru' ? repData.comment_ru : repData.comment}</td>
                        </tr>}
                        {mstation}     
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Наличие информации в базе ESWD" : "ESWD report"}</td>
                            <td>{eswdid}</td>
                        </tr>
                        {mcslink}
                        {diagvar}
                        {tornadolink}
                        {windthrowlink}
                    </tbody>
                )
            case "mcs":
                return (
                    <McsInfo 
                        mcsid={this.state.infoid}
                        closemcsinfo={this.eventInfo}
                    />  
                )
            case "diagvar":
                return (
                    <DiagvarInfo
                        diagvarid={this.state.infoid}
                        closemcsinfo={this.eventInfo}
                    />
                )
            case "ms":
                return (
                    <MeteostationInfo 
                        msid={this.state.infoid}
                        closemsinfo={this.eventInfo}
                    />  
                )
            case "tornado":
                return (
                    <TornadoInfo 
                        tornadoid={this.state.infoid}
                        closetornadoinfo={this.eventInfo}
                    />  
                )
            case "windthrow":
                return (
                    <WindthrowInfo 
                        windthrowid={this.state.infoid}
                        closewindthrowinfo={this.eventInfo}
                    />  
                )
            default: 
        }  
    }
}

const mapStateToProps = (state) => {
    return{
        msdatamap: state.msdatamap.meteojsondata,
        msdataload: state.msdatamap.meteoloaddata,
        langvocab: state.msdatamap.langvocab,
        lang: state.msdatamap.lang
    }
  };
  
  const mapDispatchToProps = () => {
    return {
        togglemsdata,
        visiblemsdata
    }
  };
  
export default connect(mapStateToProps, mapDispatchToProps())(EventInfo);