import React from 'react';
import './Map.css';
import { Button, Modal } from 'react-bootstrap';
import {connect} from 'react-redux';

class AboutInfo extends React.Component {
    render(){
        return(
            <Modal
                show={this.props.show}
                onHide={() => this.props.close(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.props.lang==="ru" ? "О проекте" : "About project"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{this.props.lang==="ru" ? 'База данных и картографический веб-сервис разработаны при поддержке РФФИ (проект № 20-35-70044 "Конвективные опасные явления погоды в центре Европейской России: каталогизация, анализ особенностей и условий формирования по разным данным")' : 'The database and cartographic web service were developed with the support of the Russian Foundation for Basic Research (project No. 20-35-70044 "Convective hazardous weather events in the center of European Russia: cataloging, analysis of the features and conditions of formation according to various data")'}</p>
                    <p>{this.props.lang==="ru" ? 'Обработка спутниковых данных выполнена при поддержке Российского научного фонда (проект № 18-77-10076 "Мезомасштабные конвективные системы над территорией России: диагностика и моделирование, механизмы формирования, связь с изменениями климата")' : 'Satellite data processing was supported by the Russian Science Foundation (project No. 18-77-10076 "Mesoscale convective systems over the territory of Russia: diagnostics and modeling, mechanisms of formation, relationship with climate change")'}</p>
                </Modal.Body>
                <Modal.Footer>
                <Button onClick={() => this.props.close(false)}>{this.props.lang==="ru" ? "Закрыть" : "Close"}</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(AboutInfo);