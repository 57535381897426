import React from 'react';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {togglemsdata, visiblemsdata} from './reduxToolkit/Reducers';
import {connect} from 'react-redux'

class WindthrowInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          info: []
        }
    }

    componentDidMount(){
        this.getWindthrowData();
    }

    getWindthrowData(){
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:windthrows_cfo_2001_2017&outputFormat=application/json&CQL_FILTER=ID=${this.props.windthrowid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:windthrows_cfo_2001_2017&outputFormat=application/json&CQL_FILTER=ID=${this.props.windthrowid}`,
            success: (data) => {
                this.setState({info: data.features[0].properties});
                this.props.togglemsdata(data.features);
                this.props.visiblemsdata(true);
            }
        })
    }

    render(){
        let windthrowdata = this.state.info;
        return(
            <tbody>
                <tr><td colSpan="2" style={{padding: '0', borderTopColor: 'white', borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'white'}}><Button onClick={() => this.props.closewindthrowinfo(false)}>{this.props.lang === 'ru' ? "Назад": "Back"}</Button></td></tr>
                <tr><td colSpan="2" style={{borderBottomColor: 'white'}}><b>{this.props.lang === 'ru' ? "Информация о ветровале:": "Information about windthrow:"}</b></td></tr>
                <tr><td colSpan="2" style={{borderTopColor: 'white'}}><i>{this.props.lang === 'ru' ? "Информация из базы данных ветровалов в лесной зоне ЕТР ": "Data from datebase of windtrow events in the forest zone of European Russia "}<a href="https://essd.copernicus.org/articles/12/3489/2020/" target="_blank" rel="noopener noreferrer">{this.props.lang === 'ru' ? "(ссылка)": "(link)"}</a></i></td></tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Год": "Year"}</td>
                    <td>{windthrowdata.Year}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Месяц": "Month"}</td>
                    <td>{windthrowdata.Month}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Дата": "Date"}</td>
                    <td>{windthrowdata.Date}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Тип явления": "Storm type"}</td>
                    <td>{windthrowdata.Storm_type}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Качество данных" : "Data quality"}</td>
                    <td>{windthrowdata.Certainty}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Время, в которое произошел ветровал (ВСВ)" : "Time range (UTC)"}</td>
                    <td>{windthrowdata.Time_range}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Площадь, км" : "Area, km"}<sup>2</sup></td>
                    <td>{Math.floor(windthrowdata.Area * 100) / 100}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Длина, км" : "Length, km"}</td>
                    <td>{windthrowdata.Length}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Средняя ширина, м" : "Average width, m"}</td>
                    <td>{Math.floor(windthrowdata.Mean_w_2 * 10) / 10}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Максимальная ширина, м" : "Maximum width, m"}</td>
                    <td>{Math.floor(windthrowdata.Max_w_2 * 10) / 10}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Направление движения шторма" : "Direction"}</td>
                    <td>{windthrowdata.Direction}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Внешняя ссылка" : "External URL"}</td>
                    <td>{windthrowdata.URL!==null && <a href={windthrowdata.URL} target="_blank" rel="noopener noreferrer">{this.props.lang === 'ru' ? "ссылка" : "link"}</a>}</td>
                </tr>
            </tbody>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        msdatamap: state.msdatamap.meteojsondata,
        msdataload: state.msdatamap.meteoloaddata,
        lang: state.msdatamap.lang
    }
};

const mapDispatchToProps = () => {
    return {
        togglemsdata,
        visiblemsdata
    }
};
export default connect(mapStateToProps, mapDispatchToProps())(WindthrowInfo);