import {createAction, createReducer} from "@reduxjs/toolkit";

const initialState = {
    meteoloaddata: false,
    meteojsondata: [],
    lang: 'ru'
}

export const togglemsdata = createAction('TOGGLEMSDATA');
export const visiblemsdata = createAction('VISIBLEMSDATA');
export const togglelang = createAction('TOGGLELANG');

export default createReducer(initialState, {
    [togglemsdata]: (state, payload) => {
        state.meteojsondata = payload;
    },
    [visiblemsdata]: (state, payload) => {
        state.meteoloaddata = payload;
    },
    [togglelang]: (state, payload) => {
        state.lang = payload.payload;
    }
})