import React from 'react';
import './App.css';
import MapComponent from './Map';

function App() {
  return (
      <MapComponent/>      
  );
}

export default App;
