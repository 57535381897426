import React from 'react';
import { MapContainer, TileLayer, GeoJSON, MapConsumer } from 'react-leaflet';
import './Map.css';
import * as L from 'leaflet';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import InfoWind from './Infowind';
import SearchReports from './Searchreports';
import StatGraphics from './Statgraphics'
import Header from './Header'
import {connect} from 'react-redux';
import {togglemsdata, visiblemsdata, togglelang} from './reduxToolkit/Reducers';
import AboutInfo from './AboutInfo';
class MapComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 55.75,
      long: 37.55,
      zoom: 5,
      info: [],
      data: [],
      datayes: false,
      isinfoopen: false,
      filteredid: [],
      filtexpression: '',
      filterdivstyle: {margin: '8px'},
      filtselregshow: true,
      statshow: false,
      aboutshow: false
    }
    this.geojson = React.createRef();
  }

  // Function for open and close Infowind
  toggleInfoWind = (payload) => {
    this.setState({
      isinfoopen: payload
    });
    this.props.togglemsdata();
    this.props.visiblemsdata(false);
    if(this.state.isinfoopen===true){
      this.geojson.current.setStyle((e)=>{
        if(this.state.filteredid.length!==0){
          if(this.state.filteredid.indexOf(e.properties.osm_id)===-1){
            return {fillColor: 'none', color: 'none'}
          }else{
            return {fillColor: '#ffc107', color: 'black'}
          }
        }else{
          return {fillColor: '#ffc107', color: 'black'}
        }
      })
    }
  }

  //Loading data for GeoJSON points
  fetchData() {
    $.ajax({
      //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:settlements_osm_with_events_all&outputFormat=application/json",
      url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:settlements_osm_with_events_all&outputFormat=application/json",
      success: (data) => {
        this.setState({
          data: data.features, 
          datayes: true
        });
      }
    })
  }

  // Click on GeoJSON points on the map
  mapClick(e){
    let pointid = e.layer.feature.properties.osm_id;
    this.setState({
      info: e.layer.feature.properties,
      isinfoopen: true,
      lat: e.latlng.lat,
      long: e.latlng.lng
    });

    this.props.togglemsdata();
    this.props.visiblemsdata(false);
    
    this.geojson.current.setStyle((e)=>{
      if(this.state.filteredid.length!==0){
        if(this.state.filteredid.indexOf(e.properties.osm_id)===-1){
          return {fillColor: 'none', color: 'none'}
        }else{
          if(e.properties.osm_id===pointid){
            return {fillColor: 'red'}
          }else{
            return {fillColor: '#ffc107', color: 'black'}
          }
        }
      }else{
        if(e.properties.osm_id===pointid){
          return {fillColor: 'red'}
        }else{
          return {fillColor: '#ffc107'}
        }
      }
    })
  }

  stylePntEvents = (feature, latlng) => {
    return L.circleMarker(latlng, {
      color: 'black',
      opacity: 0.9,
      weight: 1.3,
      fillColor: '#ffc107',
      fillOpacity: 0.8,
      radius: 5
    });
  }

  stringQuery = (filtervalues, index, attribute, func) => {
    let queryPart;
    filtervalues[index].length!==0 ? queryPart = `(${attribute} in (${filtervalues[index].map(func).join(',')}))` : queryPart = '';
    return queryPart;
  }

  stringDateQuery = (filtervalues, index, oper) => {
    let queryDatePart;
    filtervalues[index]!=='' ? queryDatePart = `report_date ${oper} ${filtervalues[index]}`: queryDatePart = '';
    return queryDatePart;
  }

  filteringData = (filtervalues) => {
    this.toggleInfoWind(false);
    let conditions = [
      this.stringQuery(filtervalues, 0, 'event_type', e => `'${e}'`),
      this.stringQuery(filtervalues, 1, 'report_type', e => `'${e}'`),
      this.stringQuery(filtervalues, 4, 'region_en', e => `'${e.value}'`),
      this.stringDateQuery(filtervalues, 2, '>='),
      this.stringDateQuery(filtervalues, 3, '<=')
    ]
    let cqlExpression = `${conditions.filter(e => e!== '').join(' and ')}`;

    this.setState({filtexpression: cqlExpression});
        
    $.ajax({
      //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:point_reports_view&outputFormat=application/json&propertyName=settlement_id&cql_filter=${cqlExpression}`,
      url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:point_reports_view&outputFormat=application/json&propertyName=settlement_id&cql_filter=${cqlExpression}`,
      success: (data) => {
        this.setState({filteredid: data.features.map(feature => 
          feature.properties.settlement_id
        )});
        this.geojson.current.setStyle((e)=>{
          if(this.state.filteredid.indexOf(e.properties.osm_id)!==-1){
            return {fillColor: '#ffc107', color: 'black'}
          }else{
            return {fillColor: 'none', color: 'none'}
          }
        });
      }
    })
  }

  clearFiltering = () => {
    this.setState({filteredid: [], filtexpression: ''});
    this.toggleInfoWind(false);
    this.geojson.current.setStyle((e)=>{
      return {fillColor: '#ffc107', color: 'black'}
    })
  }

  displayFilters = (stylediv) => {
    this.setState({
      filterdivstyle: stylediv
    })
  }

  resize() {
    if(window.innerWidth>700 && window.innerHeight>750){
      this.setState({
        filterdivstyle: {margin: '8px', display: 'block'},
        filtselregshow: true 
      });
    }else{
      this.setState({
        filterdivstyle: {margin: '8px', display: 'none'},
        filtselregshow: false  
      });
    }
  }

  setStatShow = (payload) => {
    this.setState({
      statshow: payload
    })
  }

  setAboutShow = (payload) => {
    this.setState({
      aboutshow: payload
    })
  }

  componentDidMount() {
    this.fetchData();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  render() {
    let center = [this.state.lat, this.state.long];
    return (
      // eslint-disable-next-line no-dupe-keys
      <div id="mainDiv" style={{ display: 'flex', display: '-webkit-flex', flexWrap: 'wrap', webkitFlexWrap: 'wrap', position: 'absolute', bottom: '0px', left: '0px', right: '0px', top: '0px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: '7 1 0%'}}>    
          <MapContainer
            zoom={this.state.zoom}
            center={center}
            style={{flex: 7}}
            maxBounds={([
              [39, 25],
              [63, 55]
            ])}
            minZoom="4"
          >
            <MapConsumer>
              {(map) => {
                  setTimeout(function(){map.invalidateSize()}, 0);
                  map.setView([this.state.lat, this.state.long]);
                  return null
                }
              }
            </MapConsumer>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {this.props.msdataload.payload && <GeoJSON 
              data={this.props.msdatamap.payload}
              pointToLayer={this.stylePntEvents}
              style={{fillColor: 'green'}}
            />}
            {this.state.datayes && <GeoJSON  
              data={this.state.data} 
              eventHandlers={{
                click: (e) => {
                  this.mapClick(e);
                },
              }}
              pointToLayer={this.stylePntEvents} 
              style={null} 
              ref={this.geojson}
            />}
            <Header></Header>
            <Button 
              id="aboutProject"
              size="sm"
              title={this.props.lang==='ru' ? 'О проекте' : 'About project'}
              onClick={() => {this.setAboutShow(true)}}
            ><img alt='logo' width='23px' src={process.env.PUBLIC_URL+`/about.png`}></img></Button>
            <Button
              id="languageChange"
              size="sm"
              title={this.props.lang==='ru' ? 'EN' : 'RU'} 
              onClick={() => {
                let langapp = this.props.lang==='ru' ? 'en' : 'ru';
                this.props.togglelang(langapp)}}
            ><img alt='logo' width='22px' src={process.env.PUBLIC_URL+`/language.png`}></img></Button>
            <Button 
              id="statWindowOpen"
              size="sm"
              title={this.props.lang==='ru' ? 'Статистика' : 'Statistics'}
              onClick={() => this.setStatShow(true)}
            ><img alt='logo' width='22px' src={process.env.PUBLIC_URL+`/statistics.png`}></img></Button>
            <Button 
              id="filterDivOpen"
              size="sm"
              title={this.props.lang==='ru' ? 'Фильтры' : 'Filtering reports'}
              onClick={() => {this.displayFilters({margin: '8px', display: 'block'})}}
            ><img alt='logo' width='22px' src={process.env.PUBLIC_URL+`/Filters.png`}></img></Button>
          </MapContainer>
          <SearchReports filters={this.filteringData} clearfilters={this.clearFiltering} divstyle={this.state.filterdivstyle} divstylefunc={this.displayFilters} selregshow={this.state.filtselregshow}/>
          <StatGraphics show={this.state.statshow} close={this.setStatShow}/>
          <AboutInfo show={this.state.aboutshow} close={this.setAboutShow}/>
        </div>
        {this.state.isinfoopen && <InfoWind text={this.state.info} clickopenwind={this.toggleInfoWind} filter={this.state.filtexpression}/>}
      </div> 
    )
  }
};

const mapStateToProps = (state) => {
  return{
      msdatamap: state.msdatamap.meteojsondata,
      msdataload: state.msdatamap.meteoloaddata,
      lang: state.msdatamap.lang
  }
};

const mapDispatchToProps = () => {
  return {
      togglemsdata,
      visiblemsdata,
      togglelang
  }
};

export default connect(mapStateToProps, mapDispatchToProps())(MapComponent);