import React from 'react';
import './Map.css';
import {connect} from 'react-redux';

class HeaderComponent extends React.Component {
    
    render(){
        return(
            <div id="headerApp">
                <span><img alt='logo' width='30px' style={{margin: '5px', marginLeft: '15px'}} src={process.env.PUBLIC_URL+`/wind-power.png`}></img></span>
                <span style={{marginTop: '5px', marginRight: '15px'}}>
                    {this.props.lang === 'ru' ? "ОПАСНЫЕ ЯВЛЕНИЯ ПОГОДЫ ЦЕНТРАЛЬНОГО ФЕДЕРАЛЬНОГО ОКРУГА" : "HAZARDOUS WEATHER EVENTS IN CENTRAL FEDERAL DISTRICT OF RUSSIA"}
                </span>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(HeaderComponent);