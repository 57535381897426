import React from 'react';
import $ from 'jquery';
import { Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import './Map.css';
import {connect} from 'react-redux';

class SearchReports extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          eventtypeslist: [],
          reporttypeslist: [],
          regionslist: [],
          disabledeventtypes: true,
          selectedeventtypes: [],
          disabledreporttypes: true,
          selectedreporttypes: [],
          disableddate: true,
          begindate: '',
          enddate: '',
          disabledregions: true,
          selectedregions: [],
          disabledclearbutton: true
        }
    }
    
    componentDidMount(){
        this.getFilterValuesData();
    }

    componentDidUpdate(prevProps){
        if(this.props.lang !== prevProps.lang){
            let selregvalues = [];
            this.state.selectedregions.forEach(e => {
                const filtregions = this.state.regionslist.filter(k => 
                    k.properties.name_en === e.value
                )
                selregvalues.push(...filtregions);
            })
            selregvalues = selregvalues.map(e =>{
                return {'value': e.properties.name_en, 'label': e.properties[this.props.lang === 'ru' ? 'name_ru': 'name_en']}
            })
            this.setState({selectedregions: selregvalues})
        }
    }

    getFilterValuesData(){
        $.ajax({
            //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:event_type&outputFormat=application/json",
            url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:event_type&outputFormat=application/json",
            success: (data) => {
              this.setState({eventtypeslist: data.features});
            }
        })
        $.ajax({
            //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:report_type&outputFormat=application/json",
            url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:report_type&outputFormat=application/json",
            success: (data) => {
              this.setState({reporttypeslist: data.features});
            }
        })
        $.ajax({
            //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:regions&outputFormat=application/json&sortBy=name_en",
            url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:regions&outputFormat=application/json&sortBy=name_en",
            success: (data) => {
              this.setState({regionslist: data.features});
            }
        })
    }

    switchInputChange = (event) => {
        let switchName = event.target.name;
        if(switchName==='date'){
            this.setState({
                ['disabled'+switchName]: !this.state['disabled'+switchName],
                ['begin'+switchName]: '',
                ['end'+switchName]: ''
            })
        }else{
            this.setState({
                ['disabled'+switchName]: !this.state['disabled'+switchName],
                ['selected'+switchName]: []
            })
        }
    }

    checkedInputElements = (event) => {
        let checkName = event.target.name;
        let massiveCheckedElem = this.state['selected'+checkName];
        if(massiveCheckedElem.indexOf(event.target.id)>=0){
            let index = massiveCheckedElem.indexOf(event.target.id);
            massiveCheckedElem.splice(index, 1);
            this.setState({['selected'+checkName]: massiveCheckedElem})
        }else{
            massiveCheckedElem.push(event.target.id);
            this.setState({['selected'+checkName]: massiveCheckedElem})
        }
    }

    checkedInputRegions = (event) => {
        let selobj = {};
        selobj.value = event.target.id;
        selobj.label = event.target.id;
        let massiveCheckedElem = this.state.selectedregions;
        if(massiveCheckedElem.find(item => item.value===event.target.id)){
            let index = massiveCheckedElem.findIndex(item => item.value===event.target.id);
            massiveCheckedElem.splice(index, 1);
            this.setState({selectedregions: massiveCheckedElem})
        }else{
            massiveCheckedElem.push(selobj);
            this.setState({selectedregions: massiveCheckedElem})
        }
    }

    selectedInputRegions = (event) => {
        event!==null ? this.setState({selectedregions: event}) : this.setState({selectedregions: []});
    }

    selectionDates = (event) => {
        let inputDateName = event.target.name;
        this.setState({[inputDateName]: event.target.value});
    }

    applyFilters = () => {
        let filtervalues = [];
        filtervalues.push(this.state.selectedeventtypes, this.state.selectedreporttypes, this.state.begindate, this.state.enddate, this.state.selectedregions);
        this.props.filters(filtervalues);
        this.setState({disabledclearbutton: false});
        if(window.innerWidth<701 || window.innerHeight<751){
            this.hideFilters();
        }
    }

    clearFilters = () => {
        this.setState({
            disabledeventtypes: true,
            selectedeventtypes: [],
            disabledreporttypes: true,
            selectedreporttypes: [],
            disableddate: true,
            begindate: '',
            enddate: '',
            disabledregions: true,
            selectedregions: [],
            disabledclearbutton: true
        });
        this.props.clearfilters();
        if(window.innerWidth<701 || window.innerHeight<751){
            this.hideFilters();
        }
    }

    hideFilters = () => {
        this.props.divstylefunc({margin: '8px', display: 'none'})
    }

    render(){
        let evtypes = this.state.eventtypeslist;
        let reptypes = this.state.reporttypeslist;
        let regions = this.state.regionslist;
        let regionValues = regions.map(reg => {
            const regionNames = {}
            regionNames.value = reg.properties.name_en;
            regionNames.label = this.props.lang === 'ru' ? reg.properties.name_ru: reg.properties.name_en;
            return regionNames
        });
        
        return(
            <div id="filterDiv" style={this.props.divstyle}>
                <p style={{display: 'inline-flex', width: '100%'}}> {this.props.lang === 'ru' ? "Фильтры": "Filtering event reports"} <Button onClick={this.hideFilters} id="closeFilterDiv">{this.props.lang === 'ru' ? "Закрыть": "Close"}</Button></p>
                <Form>
                    <Form.Check
                        type="switch"
                        id="event-type-switch"
                        name="eventtypes"
                        label={this.props.lang === 'ru' ? "Тип явления:": "Event type:"}
                        onChange={this.switchInputChange}
                        checked={!this.state.disabledeventtypes}
                    />
                    {evtypes.map(ev =>
                        <div key={ev.id} style={{display: 'inline-block', marginRight: '20px'}}>
                            <Form.Check
                                custom
                                disabled={this.state.disabledeventtypes}
                                checked={this.state.selectedeventtypes.indexOf(ev.properties.name)>=0}
                                type="checkbox"
                                label={this.props.lang === 'ru' ? ev.properties.name_ru: ev.properties.name}
                                id={ev.properties.name}
                                name="eventtypes"
                                onChange={this.checkedInputElements}
                            />
                        </div>
                    )}
                    <Form.Check
                        type="switch"
                        id="report-type-switch"
                        name="reporttypes"
                        label={this.props.lang === 'ru' ? "Тип регистрации:": "Report type:"}
                        onChange={this.switchInputChange}
                        checked={!this.state.disabledreporttypes}
                    />
                    {reptypes.map(rep =>
                        <div key={rep.id} style={{display: 'inline-block', marginRight: '20px'}}>
                            <Form.Check
                                custom
                                disabled={this.state.disabledreporttypes}
                                checked={this.state.selectedreporttypes.indexOf(rep.properties.name)>=0}
                                type="checkbox"
                                label={this.props.lang === 'ru' ? rep.properties.name_ru: rep.properties.name}
                                id={rep.properties.name}
                                name="reporttypes"
                                onChange={this.checkedInputElements}
                            />
                        </div>
                    )}
                    <Form.Check
                        type="switch"
                        id="date-switch"
                        name="date"
                        label={this.props.lang === 'ru' ? "Дата:": "Date:"}
                        onChange={this.switchInputChange}
                        checked={!this.state.disableddate}
                    />
                    <label style={{marginRight: '10px'}}>{this.props.lang === 'ru' ? 'с': 'from'}</label>
                    <input 
                        type="date"
                        disabled={this.state.disableddate} 
                        style={{marginRight: '10px'}}
                        name="begindate"
                        value={this.state.begindate}
                        max={this.state.enddate}
                        onChange={this.selectionDates}
                    >
                    </input>
                    <label style={{marginRight: '10px'}}>{this.props.lang === 'ru' ? 'по': 'to'}</label>
                    <input 
                        type="date"
                        disabled={this.state.disableddate} 
                        style={{marginRight: '10px'}}
                        name="enddate"
                        value={this.state.enddate}
                        min={this.state.begindate}
                        onChange={this.selectionDates}
                    >
                    </input>
                    <Form.Check
                        type="switch"
                        id="region-switch"
                        name="regions"
                        label={this.props.lang === 'ru' ? "Регион:": "Region:"}
                        onChange={this.switchInputChange}
                        checked={!this.state.disabledregions}
                    />

                    {this.props.selregshow ? 
                        <Select
                            isMulti
                            isDisabled={this.state.disabledregions}
                            options={regionValues}
                            maxMenuHeight="185px"
                            onChange={this.selectedInputRegions}
                            value={this.state.selectedregions}
                            menuPlacement="top"
                            placeholder={this.props.lang === 'ru' ? "Выберите регионы...": "Select regions..."}
                        /> :
                        regions.map(regrf =>
                            <div key={regrf.id} style={{display: 'inline-block', marginRight: '20px'}}>
                                <Form.Check
                                    custom
                                    disabled={this.state.disabledregions}
                                    checked={this.state.selectedregions.findIndex(item => item.value===regrf.properties.name_en)>=0}
                                    type="checkbox"
                                    label={this.props.lang === 'ru' ? regrf.properties.name_ru: regrf.properties.name_en}
                                    id={regrf.properties.name_en}
                                    name="regions"
                                    onChange={this.checkedInputRegions}
                                />
                            </div>
                        )
                    }

                    <p style={{marginTop: '10px'}}>
                        <Button 
                            style={{marginRight: '10px'}}
                            onClick={this.applyFilters}
                            disabled={
                                (this.state.disabledeventtypes && this.state.disabledreporttypes && this.state.disabledregions && this.state.disableddate) ||
                                (!this.state.disabledeventtypes && this.state.selectedeventtypes.length===0) ||
                                (!this.state.disabledreporttypes && this.state.selectedreporttypes.length===0) ||
                                (!this.state.disabledregions && this.state.selectedregions.length===0) ||
                                (!this.state.disableddate && (this.state.begindate==='' && this.state.enddate===''))
                            }
                        >{this.props.lang === 'ru' ? "Применить фильтр": "Apply filters"}</Button>
                        <Button
                            onClick={this.clearFilters}
                            disabled={this.state.disabledclearbutton}
                        >{this.props.lang === 'ru' ? "Очистить фильтр": "Clear filters"}</Button>
                    </p>
                </Form>
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(SearchReports);