import React from 'react';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {connect} from 'react-redux'

class DiagvarInfo extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          infocfs: [],
          infoera: [],
        }
    }

    componentDidMount(){
        this.getDiagvarData();
    }

    getDiagvarData(){
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:diagnostic_variables_cfs&outputFormat=application/json&featureID=${this.props.diagvarid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:diagnostic_variables_cfs&outputFormat=application/json&featureID=${this.props.diagvarid}`,
            success: (data) => {
              this.setState({infocfs: data.features[0].properties});
            }
        })
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:diagnostic_variables_era5&outputFormat=application/json&featureID=${this.props.diagvarid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:diagnostic_variables_era5&outputFormat=application/json&featureID=${this.props.diagvarid}`,
            success: (data) => {
              this.setState({infoera: data.features[0].properties});
            }
        })
    }
    
    render(){
        let cfsdata = this.state.infocfs;
        let eradata = this.state.infoera;
        return(
            <tbody>
                <tr><td colSpan="3" style={{padding: '0', borderTopColor: 'white', borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'white'}}><Button onClick={() => this.props.closemcsinfo(false)}>{this.props.lang === 'ru' ? "Назад": "Back"}</Button></td></tr>
                <tr><td colSpan="3"><b>{this.props.lang === 'ru' ? "Диагностические переменные по данным реанализа:": "Information about reanalysis diagnostic variables:"}</b></td></tr>
                <tr> 
                    <th>{this.props.lang === 'ru' ? "Параметр": "Parameter"}</th>
                    <th>CFS</th>
                    <th>ERA5</th>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Дата": "Date"}</td>
                    <td>{cfsdata.day}.{cfsdata.month}.{cfsdata.year}</td>
                    <td>{eradata.day}.{eradata.month}.{eradata.year}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Время ВСВ": "Time (UTC)"}</td>
                    <td>{cfsdata.time_utc}</td>
                    <td>{eradata.time_utc}</td>
                </tr>
                <tr> 
                    <td>TT</td>
                    <td>{cfsdata.tt}</td>
                    <td>{eradata.tt}</td>
                </tr>
                <tr> 
                    <td>K</td>
                    <td>{cfsdata.k}</td>
                    <td>{eradata.k}</td>
                </tr>
                <tr> 
                    <td>CAPE</td>
                    <td>{cfsdata.cape}</td>
                    <td>{eradata.cape}</td>
                </tr>
                <tr> 
                    <td>F3D</td>
                    <td>{cfsdata.f3d}</td>
                    <td>{eradata.f3d}</td>
                </tr>
                <tr> 
                    <td>SWEAT</td>
                    <td>{cfsdata.sweat}</td>
                    <td>{eradata.sweat}</td>
                </tr>
                <tr> 
                    <td>DLS</td>
                    <td>{cfsdata.dls}</td>
                    <td>{eradata.dls}</td>
                </tr>
                <tr> 
                    <td>MLS</td>
                    <td>{cfsdata.mls}</td>
                    <td>{eradata.mls}</td>
                </tr>
                <tr> 
                    <td>LLS</td>
                    <td>{cfsdata.lls}</td>
                    <td>{eradata.lls}</td>
                </tr>
                <tr> 
                    <td>WMAXSHEAR</td>
                    <td>{cfsdata.wmaxshear}</td>
                    <td>{eradata.wmaxshear}</td>
                </tr>
                <tr> 
                    <td>EHI</td>
                    <td>{cfsdata.ehi}</td>
                    <td>{eradata.ehi}</td>
                </tr>
                <tr> 
                    <td>Prexwat</td>
                    <td>{cfsdata.prexwat}</td>
                    <td>{eradata.prexwat}</td>
                </tr>
                <tr> 
                    <td>SRH3000</td>
                    <td>{cfsdata.srh3000}</td>
                    <td>{eradata.srh3000}</td>
                </tr>
            </tbody>
        )
    }

}

const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(DiagvarInfo);