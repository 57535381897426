import React from 'react';
import './Map.css';
import $ from 'jquery';
import { Button, Modal, Tabs, Tab } from 'react-bootstrap';
import {connect} from 'react-redux';
import { BarChart, PieChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, Label, Pie, ResponsiveContainer, Legend, Cell } from 'recharts'; 

class StatGraphics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      graphsalldata: [],
      yeardata: [],
      eventlist: [],
      regionlist: [],
      eventtype: 'All events',
      regionvalue: 'All regions',
      monthdata: [],
      dailydata: [],
      reptypedata: [],
      eventtypedata: [],
      intensitydata: [],
      intensityunit: "mm",
      injdata: [],
      regionvalueevtypegr: 'All regions',
      eventtypemonthgr: 'All events',
      eventtypeintensitygr: 'All events'
    }
  }

  componentDidMount(){
    this.getGraphicsData();
  }

  componentDidUpdate(prevProps){
    if(this.props.lang !== prevProps.lang){      
      this.calcDataMonthGraph({target: {value: 'All events'}});
      this.calcDataReportTypeGraph();
      this.calcDataDailyGraph();
      this.calcDataEventTypeGraph({target: {value: 'All regions'}});
      this.calcDataIntensityGraph({target: {value: 'Heavy rain'}});
    }
  }

  getGraphicsData(){
    $.ajax({
      //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:regions&outputFormat=application/json&sortBy=name_en",
      url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:regions&outputFormat=application/json&sortBy=name_en",
      success: (data) => {
        this.setState({regionlist: data.features});
      }
    })
    $.ajax({
      //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:event_type&outputFormat=application/json",
      url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:event_type&outputFormat=application/json",
      success: (data) => {
        this.setState({eventlist: data.features});
      }
    })
    $.ajax({
        //url: "http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:point_reports_view&outputFormat=application/json&propertyName=year,month,time_start_utc,report_type,report_type_ru,region_en,region_ru,event_type,event_type_ru,intensity,injured,dead",
        url: "https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:point_reports_view&outputFormat=application/json&propertyName=year,month,time_start_utc,report_type,report_type_ru,region_en,region_ru,event_type,event_type_ru,intensity,injured,dead",
        success: (data) => {
          this.setState({
            graphsalldata: data.features.map(e =>
              e.properties
            )
          });
          this.calcDataYearGraph({target: {value: this.state.regionvalue, id: "selectRegion"}});
          this.calcDataMonthGraph({target: {value: 'All events'}});
          this.calcDataDailyGraph();
          this.calcDataReportTypeGraph();
          this.calcDataEventTypeGraph({target: {value: 'All regions'}});
          this.calcDataIntensityGraph({target: {value: 'Heavy rain'}});
          this.calcDataInjGraphs()
        }
    })
  }
    
  calcDataYearGraph = async (event) => {
    let arrdata;
    switch (event.target.id) {
      case "selectRegion":
        await this.setState({
          regionvalue: event.target.value
        })
        break;
      case "selectEvent":
        await this.setState({
          eventtype: event.target.value
        })
        break;
      default:
    }

    if(this.state.regionvalue === "All regions" && this.state.eventtype === "All events"){
      arrdata = this.state.graphsalldata      
    }else if(this.state.regionvalue !== "All regions" && this.state.eventtype === "All events"){
      arrdata = this.state.graphsalldata.filter(e => e.region_en === this.state.regionvalue)
    }else if (this.state.regionvalue === "All regions" && this.state.eventtype !== "All events"){
      arrdata = this.state.graphsalldata.filter(e => e.event_type === this.state.eventtype)
    }else if(this.state.regionvalue !== "All regions" && this.state.eventtype !== "All events"){
      arrdata = this.state.graphsalldata.filter(e => (e.region_en === this.state.regionvalue) && (e.event_type === this.state.eventtype))
    }
    let countyear = this.calcCountsForGraphs(arrdata.map(e => e.year));
    this.setState({
      yeardata: countyear
    })
  }

  calcDataMonthGraph = (event) => {
    this.setState({
      eventtypemonthgr: event.target.value
    })
    let countmonth = {};
    let arrdatamonth;
    
    let monthslist = {
      1: {
        en: 'January',
        ru: 'Январь'
      },
      2: {
        en: 'February',
        ru: 'Февраль'
      },
      3: {
        en: 'March',
        ru: 'Март'
      },
      4: {
        en: 'April',
        ru: 'Апрель'
      },
      5: {
        en: 'May',
        ru: 'Май'
      },
      6: {
        en: 'June',
        ru: 'Июнь'
      },
      7: {
        en: 'July',
        ru: 'Июль'
      },
      8: {
        en: 'August',
        ru: 'Август'
      },
      9: {
        en: 'September',
        ru: 'Сентябрь'
      },
      10: {
        en: 'October',
        ru: 'Октябрь'
      },
      11: {
        en: 'November',
        ru: 'Ноябрь'
      },
      12: {
        en: 'December',
        ru: 'Декабрь'
      }
    }

    if(event.target.value === 'All events'){
      arrdatamonth = this.state.graphsalldata;
    }else {
      arrdatamonth = this.state.graphsalldata.filter(e => e.event_type === event.target.value)
    }

    for (let elem of arrdatamonth) {        
      if (countmonth[elem.month] === undefined) {
        countmonth[elem.month] = 1;
      } else {
        countmonth[elem.month]++;
      }        
    }
    countmonth = Object.keys(countmonth).map(e =>{
      return {month: monthslist[Number(e)][this.props.lang], cnt: countmonth[e]}
    })
    this.setState({
      monthdata: countmonth
    })
  }

  calcDataDailyGraph(){
    let arrtimes = [];
    let arrdatadaily = this.state.graphsalldata.filter(e => e.event_type !== "Heavy rain");
    arrdatadaily.map(e => {
      if(e.time_start_utc === null){
        arrtimes.push(this.props.lang==="ru" ? "Нет данных" : "No data");
      }else{
        switch (e.time_start_utc.substr(0, 2)) {
          case "00":
          case "01":
          case "02":
            arrtimes.push("00-03");            
            break;
          case "03":
          case "04":
          case "05":
            arrtimes.push("03-06");
            break;
          case "06":
          case "07":
          case "08":
            arrtimes.push("06-09");
            break;
          case "09":
          case "10":
          case "11":
            arrtimes.push("09-12");
            break;
          case "12":
          case "13":
          case "14":
            arrtimes.push("12-15");
            break;
          case "15":
          case "16":
          case "17":
            arrtimes.push("15-18");
            break;
          case "18":
          case "19":
          case "20":
            arrtimes.push("18-21");
            break;
          case "21":
          case "22":
          case "23":
            arrtimes.push("21-00");
            break;
          default:
            break;
        }
      }
      return arrtimes.sort();
    });
    let countdaily = this.calcCountsForGraphs(arrtimes);
    this.setState({
      dailydata: countdaily
    })
   }

  calcDataReportTypeGraph(){
    let arrdatareptype = this.state.graphsalldata.map(e => this.props.lang==="ru" ? e.report_type_ru : e.report_type);
    let countreptype = this.calcCountsForGraphs(arrdatareptype);
    this.setState({
      reptypedata: countreptype
    })
  }

  calcDataEventTypeGraph = (event) => {
    this.setState({
      regionvalueevtypegr: event.target.value
    })
    let arrdataeventtype;
    if(event.target.value === 'All regions'){
      arrdataeventtype = this.state.graphsalldata.map(e => this.props.lang==="ru" ? e.event_type_ru : e.event_type);
    }else {
      arrdataeventtype = this.state.graphsalldata.filter(e => e.region_en === event.target.value);
      arrdataeventtype = arrdataeventtype.map(e => this.props.lang==="ru" ? e.event_type_ru : e.event_type);
    }
    let counteventtype = this.calcCountsForGraphs(arrdataeventtype); 
    this.setState({
      eventtypedata: counteventtype
    })
  }

  calcDataIntensityGraph = (event) => {
    this.setState({
      eventtypeintensitygr: event.target.value
    })
    let variables = {
      "heavy_rain": [[30, 40, "30-40"],[40, 50, "40-50"],[50, 60, "50-60"],[60, 75, "60-75"],[75, 100, "75-100"],[100, 1000, ">100"]],
      "large_hail": [[20, 30, "20-30"],[30, 40, "30-40"],[40, 50, "40-50"],[50, 500, ">50"]],
      "squall": [[1, 25, "<25"],[25, 26, "25"],[26, 27, "26"],[27, 28, "27"],[28, 29, "28"],[29, 30, "29"],[30, 31, "30"],[31, 100, ">30"]]
    }
    let counteventintensity = {};
    let arrdataintensity = this.state.graphsalldata.filter(e => e.event_type === event.target.value);

    switch (event.target.value) {
      case 'Heavy rain':
        counteventintensity = calcCountIntensity(variables.heavy_rain);
        this.setState({
          intensityunit: this.props.lang==="ru" ? "мм" : "mm"
        })
        break;
      case 'Large hail':
        counteventintensity = calcCountIntensity(variables.large_hail);
        this.setState({
          intensityunit: this.props.lang==="ru" ? "мм" : "mm"
        })
        break;
      case 'Squall':
        counteventintensity = calcCountIntensity(variables.squall);
        this.setState({
          intensityunit: this.props.lang==="ru" ? "м/с" : "m/s"
        })
        break;
      case 'Tornado':
        counteventintensity = this.calcCountsForGraphs(arrdataintensity.map(e => e.intensity));
        counteventintensity.map(e => {
          for (let key in e){
            console.log(e[key]);
            if(e[key] === "null"){
                e[key] = this.props.lang==="ru" ? "Нет данных" : "No data";
            }
          }
          return e
        })
        this.setState({
          intensityunit: this.props.lang==="ru" ? "по шкале Фуджита" : "F-scale"
        })
        break;
      default:
        break;
    }
    this.setState({
      intensitydata: counteventintensity
    })
    function calcCountIntensity(eventtypeint){
      let resultinterval = eventtypeint.map(interval => {
        let mass = arrdataintensity.filter(k => k.intensity>=interval[0] && k.intensity<interval[1]);
        return {keyvalue: interval[2], cnt: mass.length}
      });
      return resultinterval
    }
  }

  calcDataInjGraphs(){
    let arrdatainj = this.state.graphsalldata.map(e => {
      let arrcount = [e.year, e.injured, e.dead]; 
      return arrcount
    })
    let countinj = {};
    for (let elem of arrdatainj) {        
      if (countinj[elem[0]] === undefined) {
        countinj[elem[0]] = {"year": elem[0], "injured": elem[1], "dead": elem[2]};
      } else {
        countinj[elem[0]]["injured"] = countinj[elem[0]]["injured"]+elem[1];
        countinj[elem[0]]["dead"] = countinj[elem[0]]["dead"]+elem[2];
      }        
    }
    countinj = Object.keys(countinj).map(e =>{
      return countinj[e]
    });
    this.setState({
      injdata: countinj
    })
  }

  calcCountsForGraphs = (arrdata) => {
    let count = {};
    for (let elem of arrdata) {        
      if (count[elem] === undefined) {
        count[elem] = 1;
      } else {
        count[elem]++;
      }        
    }
    count = Object.keys(count).map(e =>{
      return {keyvalue: e, cnt: count[e]}
    });
    return count;
  }

  uniqueValues(arr) {
    return Array.from(new Set(arr));
  }

  render(){
    const piecolors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#CB2821', '#806274', '#4F7942'];
    return(
      <Modal
        show={this.props.show}
        onHide={() => this.props.close(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {this.props.lang==="ru" ? "Статистическая информация" : "Report statistics"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="tab0" id="uncontrolled-tab-example">

            <Tab eventKey="tab0" title={this.props.lang==="ru" ? "Типы явлений" : "Event types"}>
              <div>
                <span style={{marginRight: '20px'}}>
                  <label for="selectRegionEventTypes" style={{marginRight: '7px'}}>{this.props.lang==="ru" ? "Выберите регион" : "Select region:"}</label>
                  <select id="selectRegionEventTypes" onChange={this.calcDataEventTypeGraph} value={this.state.regionvalueevtypegr}>
                    <option value="All regions">{this.props.lang==="ru" ? "Все регионы" : "All regions"}</option>
                    {this.state.regionlist.map((e) =>
                      <option value={e.properties.name_en} key={e.properties.name_en}>{this.props.lang==="ru" ? e.properties.name_ru : e.properties.name_en}</option>
                    )}
                  </select>
                </span>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <PieChart width={500} height={250}>
                    <Pie data={this.state.eventtypedata} dataKey="cnt" nameKey="keyvalue" fill="#8884d8" isAnimationActive={true}>
                      {this.state.eventtypedata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={piecolors[index % piecolors.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Tab>

            <Tab eventKey="tab1" title={this.props.lang==="ru" ? "Распределение по годам" : "Inter-annual distribution"}>
              <div>
                <span style={{marginRight: '20px'}}>
                  <label for="selectRegion" style={{marginRight: '7px'}}>{this.props.lang==="ru" ? "Выберите регион" : "Select region:"}</label>
                  <select id="selectRegion" onChange={this.calcDataYearGraph} value={this.state.regionvalue}>
                    <option value="All regions">{this.props.lang==="ru" ? "Все регионы" : "All regions"}</option>
                    {this.state.regionlist.map((e) =>
                      <option value={e.properties.name_en} key={e.properties.name_en}>{this.props.lang==="ru" ? e.properties.name_ru : e.properties.name_en}</option>
                    )}
                  </select>
                </span>
                <span>
                  <label for="selectEvent" style={{marginRight: '7px'}}>{this.props.lang==="ru" ? "Выберите тип явления" : "Select event type:"}</label> 
                  <select id="selectEvent" onChange={this.calcDataYearGraph} value={this.state.eventtype}>
                    <option value="All events">{this.props.lang==="ru" ? "Все явления" : "All events"}</option>
                    {this.state.eventlist.map(e =>
                      <option value={e.properties.name} key={e.properties.name}>{this.props.lang==="ru" ? e.properties.name_ru : e.properties.name}</option>
                    )}
                  </select>
                </span>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart width={730} height={250} data={this.state.yeardata} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="keyvalue">
                      <Label value={this.props.lang==="ru" ? "Годы" : "Years"} position='insideBottom' offset={-5}/>
                    </XAxis>
                    <YAxis >
                      <Label value={this.props.lang==="ru" ? "Количество" : "Number"} position='insideLeft' angle={-90} />
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="cnt" fill="#1e90ff" name={this.props.lang==="ru" ? "количество отчетов" : "number of reports"}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Tab>

            <Tab eventKey="tab2" title={this.props.lang==="ru" ? "Распределение по месяцам" :"Monthly distribution"}>
              <div>
                <span>
                  <label for="selectEventMonthly" style={{marginRight: '7px'}}>{this.props.lang==="ru" ? "Выберите тип явления" : "Select event type:"}</label> 
                  <select id="selectEventMonthly" onChange={this.calcDataMonthGraph} value={this.state.eventtypemonthgr}>
                    <option value="All events">{this.props.lang==="ru" ? "Все явления" : "All events"}</option>
                    {this.state.eventlist.map(e =>
                      <option value={e.properties.name} key={e.properties.name}>{this.props.lang==="ru" ? e.properties.name_ru : e.properties.name}</option>
                    )}
                  </select>
                </span>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart width={730} height={250} data={this.state.monthdata} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month">
                      <Label value={this.props.lang==="ru" ? "Месяцы" : "Months"} position='insideBottom' offset={-5}/>
                    </XAxis>
                    <YAxis>
                      <Label value={this.props.lang==="ru" ? "Количество" : "Number"} position='insideLeft' angle={-90} />
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="cnt" fill="#1e90ff" name={this.props.lang==="ru" ? "количество отчетов" : "number of reports"}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Tab>

            <Tab eventKey="tab3" title={this.props.lang==="ru" ? "Суточный ход" : "Diurnal cycle"}>
              <div>{this.props.lang==="ru" ? "Только крупный град, шквалы и смерчи" : "Only large hail, squalls and tornadoes events"}</div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart width={730} height={250} data={this.state.dailydata} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="keyvalue">
                      <Label value={this.props.lang==="ru" ? "Время начала, ч (ВСВ)" : "Time start, h (UTC)"} position='insideBottom' offset={-5}/>
                    </XAxis>
                    <YAxis>
                      <Label value={this.props.lang==="ru" ? "Количество" : "Number"} position='insideLeft' angle={-90} />
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="cnt" fill="#1e90ff" name={this.props.lang==="ru" ? "количество отчетов" : "number of reports"}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Tab>

            <Tab eventKey="tab4" title={this.props.lang==="ru" ? "Распределение по интенсивности" : "Intensity distribution"}>
              <div>
                <span>
                  <label for="selectEventIntensity" style={{marginRight: '7px'}}>{this.props.lang==="ru" ? "Выберите тип явления" : "Select event type:"}</label> 
                  <select id="selectEventIntensity" onChange={this.calcDataIntensityGraph} value={this.state.eventtypeintensitygr}>
                    {this.state.eventlist.map(e =>
                      <option value={e.properties.name} key={e.properties.name}>{this.props.lang==="ru" ? e.properties.name_ru : e.properties.name}</option>
                    )}
                  </select>
                </span>
              </div>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart width={730} height={250} data={this.state.intensitydata} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="keyvalue">
                      <Label value={this.state.intensityunit} position='insideBottom' offset={-5}/>
                    </XAxis>
                    <YAxis >
                      <Label value={this.props.lang==="ru" ? "Количество" : "Number"} position='insideLeft' angle={-90} />
                    </YAxis>
                    <Tooltip />
                    <Bar dataKey="cnt" fill="#1e90ff" name={this.props.lang==="ru" ? "количество отчетов" : "number of reports"}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Tab>

            <Tab eventKey="tab5" title={this.props.lang==="ru" ? "Источники данных" : "Report types"}>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <PieChart width={500} height={250}>
                    <Pie data={this.state.reptypedata} dataKey="cnt" nameKey="keyvalue" fill="#8884d8" isAnimationActive={true}>
                      {this.state.reptypedata.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={piecolors[index % piecolors.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </Tab>

            <Tab eventKey="tab6" title={this.props.lang==="ru" ? "Погибшие и пострадавшие" : "Fatalities and injuries"}>
              <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                  <BarChart width={730} height={250} data={this.state.injdata} margin={{ top: 15, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="year">
                      <Label value={this.props.lang==="ru" ? "Годы" : "Years"} position='insideBottom' offset={-5}/>
                    </XAxis>
                    <YAxis >
                      <Label value={this.props.lang==="ru" ? "Человек" : "Peoples"} position='insideLeft' angle={-90} />
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="dead" stackId="a"fill="#9b2d30" name={this.props.lang==="ru" ? "погибших" : "deaths"}/>          
                    <Bar dataKey="injured" stackId="a" fill="#e5be01" name={this.props.lang==="ru" ? "пострадавших" : "injuries"}/>
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Tab>

          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.close(false)}>{this.props.lang==="ru" ? "Закрыть" : "Close"}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(StatGraphics);