import React from 'react';
import $ from 'jquery';
import {Collapse, Button} from 'reactstrap';
import {connect} from 'react-redux';

class McsImage extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          info: [],
          buttonimageclickid: null,
          moreinfo: false,
          infoid: null,
        }
    }

    componentDidMount(){
        this.getMcsImages();
    }

    getMcsImages(){
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:mcs_images_view&outputFormat=application/json&CQL_FILTER=mcs_report_id=${this.props.mcsid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:mcs_images_view&outputFormat=application/json&CQL_FILTER=mcs_report_id=${this.props.mcsid}`,
            success: (data) => {
              this.setState({info: data.features});
            }
        })
    }

    imageInfo = (id) => {
        this.setState(prevState => ({
            buttonimageclickid: prevState.buttonimageclickid!== id ? id : null
        })
        );
    }

    eventInfo = (payload) => {
        this.setState({
            moreinfo: payload
        });
    }
    
    render(){
        let imginfo = this.state.info;
        return(
            <tbody>
                <tr key='satellite_images'><td colSpan="4"><b>{this.props.lang === 'ru' ? "Спутниковые снимки:": "Satellite images:"}</b></td></tr>
                <tr key='column_names' style={{textAlign: "center"}}>
                    <th>{this.props.lang === 'ru' ? "Номер / Время съемки (ВСВ)": "Number / Time (UTC)"}</th>
                    <th>{this.props.lang === 'ru' ? "Температура верхней границы облачности": "Cloud top temerature"}</th>
                    <th>{this.props.lang === 'ru' ? "Снимки в высоком разрешении": "High-resolution image"}</th>
                    <th></th>
                </tr>
                {imginfo.map((i, index) => [                    
                    <tr style={{textAlign: "center"}} key={i.properties.id}>
                        <td>{index+1} / {i.properties.time_utc}</td>
                        <td>
                            <a href={process.env.PUBLIC_URL+`/images/${i.properties.ctt_image_file_name}`} target="_blank" rel="noopener noreferrer">
                                <img alt='logo' width="100em" src={process.env.PUBLIC_URL+`/images/${i.properties.ctt_image_file_name}`}></img>
                            </a>
                        </td>
                        {i.properties.hrv_image_file_name!==null ? <td><a href={process.env.PUBLIC_URL+`/images/${i.properties.hrv_image_file_name}`} target="_blank" rel="noopener noreferrer"><img alt='' width="100em" src={process.env.PUBLIC_URL+`/images/${i.properties.hrv_image_file_name}`}></img></a></td> : <td></td>}
                        <td><Button color="warning" size="sm" onClick={() => this.imageInfo(i.properties.id)}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>,
                    <tr key='satellite_image_info'><td colSpan="4">
                    <Collapse isOpen={this.state.buttonimageclickid===i.properties.id}>
                        <table>
                            <tbody>
                                <tr><td colSpan="2"><b>{this.props.lang === 'ru' ? "Информация о снимке:" : "Information about image:"}</b></td></tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Спутник" : "Satellite"}</td>
                                    <td>{i.properties.satellite}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Дата" : "Date"}</td>
                                    <td>{i.properties.day}.{i.properties.month}.{i.properties.year}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Время (ВСВ)" : "Time (UTC)"}</td>
                                    <td>{i.properties.time_utc}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Тип МКС" : "MCS type"}</td>
                                    <td>{this.props.lang === 'ru' ? i.properties.mcs_type_ru : i.properties.mcs_type}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Минимальная температура верхней границы облачности" : "Minimum cloud top temperature"}, &deg;C</td>
                                    <td>{i.properties.min_cloud_top_temperature_c}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Диаметр МКС по большой оси, км" : "MCS diameter major axis, km"}</td>
                                    <td>{i.properties.mcs_diameter_major_axis}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Диаметр МКС по малой оси, км" : "MCS diameter minor axis, km"}</td>
                                    <td>{i.properties.mcs_diameter_minor_axis}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Тип сигнатуры" : "Signature type"}</td>
                                    <td>{this.props.lang === 'ru' ? i.properties.signature_type_ru : i.properties.signature_type}</td>
                                </tr>
                                <tr>
                                    <td>{this.props.lang === 'ru' ? "Продолжительность существования сигнатуры, мин" : "Signature duration, min"}</td>
                                    <td>{i.properties.signature_duration}</td>
                                </tr>
                                {i.properties.id_tornadoes_database!==null && 
                                    <tr>
                                        <td>{this.props.lang === 'ru' ? "Смерч" : "Tornado"}</td>
                                        <td><Button color="warning" size="sm" onClick={() => {this.props.moreinfo(i.properties.id_tornadoes_database, "tornado")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                                    </tr>
                                }
                                {i.properties.id_windthrow_database!==null && 
                                    <tr>
                                        <td>{this.props.lang === 'ru' ? "Ветровал" : "Windthrow"}</td>
                                        <td><Button color="warning" size="sm" onClick={() => {this.props.moreinfo(i.properties.id_windthrow_database, "windthrow")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </Collapse>
                    </td></tr>
                ])}
            </tbody>         
        )
    }
}

const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(McsImage);