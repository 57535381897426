import React from 'react';
import $ from 'jquery';
import './Map.css';
import EventInfo from './Eventinfo';
import {connect} from 'react-redux';
import { Button, Table } from 'react-bootstrap';

class EventList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          info: [],
          fullinfo: false,
          allrepinfo: null
        }
    }
    
    render() {
        if(this.state.fullinfo === false){
            return (
                <Table striped size="sm">
                    {this.getObjData()}
                </Table>            
            )
        }else{
            return (
                <Table striped bordered size="sm">
                   <EventInfo 
                        allevinfo={this.state.allrepinfo}
                        closeeventinfo={this.eventsInfo}
                    />
                </Table>
            )
        }
    }

    componentDidMount() {
        this.getAllData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.osmid !== prevProps.osmid) {
            this.setState({fullinfo: false});
            this.getAllData();
        }
    }

    getAllData(){
        let queryEvents = [this.props.osmid, this.props.filter]
        let cqlExpression = `${queryEvents.filter(e => e!== '').join(' and ')}`;
        $.ajax({
          //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:point_reports_view&outputFormat=application/json&CQL_FILTER=settlement_id=${cqlExpression}`,
          url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:point_reports_view&outputFormat=application/json&CQL_FILTER=settlement_id=${cqlExpression}`,
          success: (data) => {
            this.setState({info: data.features});
          }
        })
    }

    allInfo = (i) => {
        this.setState({
            fullinfo: true, 
            allrepinfo: i.properties
        });
    }

    eventsInfo = (payload) => {
        this.setState({
            fullinfo: payload
        });
    }

    getObjData(){
        let info = this.state.info;
        return(
            <tbody>
                <tr><td colSpan="3" style={{borderColor: 'white'}}><b>{this.props.lang === 'ru' ? this.props.name_ru : this.props.name_en}</b></td></tr>
                <tr><td colSpan="3" style={{borderTopColor: 'white'}}><b>{this.props.lang === 'ru' ? "Опасные явления погоды:" : "Hazardous weather events:"}</b></td></tr>
                {info.map(i =>
                    <tr key={i.properties.report_number}>
                        <td>{this.props.lang === 'ru' ? i.properties.event_type_ru : i.properties.event_type}</td>
                        <td>{i.properties.day}.{i.properties.month}.{i.properties.year}</td>
                        <td><Button size="sm" variant="warning" onClick={() => this.allInfo(i)}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>               
                )}
            </tbody>
        );
    }

}
const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(EventList);