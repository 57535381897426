import React from 'react';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import DiagvarInfo from './Diagvarinfo';
import McsImage from './Mcsimages';
import TornadoInfo from './Tornadoinfo';
import WindthrowInfo from './WindthrowInfo';
import {connect} from 'react-redux';
import {togglemsdata, visiblemsdata} from './reduxToolkit/Reducers';

class McsInfo extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
          info: [],
          moreinfo: false,
          infoid: null
        }
    }

    componentDidMount(){
        this.getMcsData();
    }

    getMcsData(){
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:mcs_reports_view&outputFormat=application/json&CQL_FILTER=mcs_report=${this.props.mcsid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:mcs_reports_view&outputFormat=application/json&CQL_FILTER=mcs_report=${this.props.mcsid}`,
            success: (data) => {
              this.setState({info: data.features[0].properties});
            }
        })
    }

    moreInfoFunc = (i, tbname) => {
        this.setState({
            moreinfo: tbname,
            infoid: i
        });
    }

    mcsInfo = (payload) => {
        this.setState({
            moreinfo: payload
        });
        this.props.togglemsdata();
        this.props.visiblemsdata(false);
    }
    
    render(){
        let mcsData = this.state.info;
        let diagvar;
        if(mcsData.diagnostic_variables_id!==null){
            diagvar = <tr>
                        <td>{this.props.lang === 'ru' ? "Диагностические переменные": "Diagnostic variables"}</td>
                        <td><Button size="sm" variant="warning" onClick={() => {this.moreInfoFunc(mcsData.diagnostic_variables_id, "diagvar")}}><img alt='logo' src={process.env.PUBLIC_URL+`/info.svg`}></img></Button></td>
                    </tr>;
        }
        
        switch(this.state.moreinfo){
            case false:
                return[
                    <tbody key="1">
                        <tr><td colSpan="2" style={{padding: '0', borderTopColor: 'white', borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'white'}}><Button onClick={() => this.props.closemcsinfo(false)}>{this.props.lang === 'ru' ? "Назад": "Back"}</Button></td></tr>
                        <tr><td colSpan="2"><b>{this.props.lang === 'ru' ? "Информация о мезомасштабной конвективной системе (МКС):": "Information about mesoscale convective system (MCS):"}</b></td></tr>
                        <tr>
                            <td>{this.props.lang === 'ru' ? "Дата": "Date"}</td>
                            <td>{mcsData.day}.{mcsData.month}.{mcsData.year}</td>
                        </tr>
                        <tr>    
                            <td>{this.props.lang === 'ru' ? "Время получения первого космического снимка (ВСВ)": "Time first image (UTC)"}</td>
                            <td>{mcsData.time_first_image_utc}</td>
                        </tr>
                        <tr>    
                            <td>{this.props.lang === 'ru' ? "Время получения последнего космического снимка (ВСВ)": "Time last image (UTC)"}</td>
                            <td>{mcsData.time_last_image_utc}</td>
                        </tr>
                        <tr>    
                            <td>{this.props.lang === 'ru' ? "Продолжительность существования, ч": "Life duration, h"}</td>
                            <td>{mcsData.mcs_life_duration_h}</td>
                        </tr>
                        <tr>    
                            <td>{this.props.lang === 'ru' ? "Направление движения": "Movement direction"}</td>
                            <td>{this.props.lang === 'ru' ? mcsData.mcs_moverment_direction_ru : mcsData.mcs_moverment_direction}</td>
                        </tr>
                        {diagvar}
                        {(mcsData.hrv_video_file_name !==null || mcsData.ctt_video_file_name !==null) && <tr key='animations'>
                            <td colSpan="4"><b>{this.props.lang === 'ru' ? "Анимации развития облачности по снимкам Meteosat:": "Meteosat animations:"}</b></td>
                        </tr>}
                        {mcsData.hrv_video_file_name !==null && <tr key='hrv_video'>
                            <td colSpan="4" style={{textAlign: "center"}}>{this.props.lang === 'ru' ? "Снимки в высоком разрешении": "HRV cloud"}</td>
                        </tr>}
                        {mcsData.hrv_video_file_name !==null && <tr key='hrv_video_file'>
                            <td colSpan="4" style={{textAlign: "center"}}>
                                <video width="300em" controls src={process.env.PUBLIC_URL+`/video/${mcsData.hrv_video_file_name}`}></video>
                            </td>
                        </tr>}
                        {mcsData.ctt_video_file_name !==null && <tr key='ctt_video'>
                            <td colSpan="4" style={{textAlign: "center"}}>{this.props.lang === 'ru' ? "Температура верхней границы облачности": "Cloud top temperature"}</td>
                        </tr>}
                        {mcsData.ctt_video_file_name !==null && <tr key='ctt_video_file'>
                            <td colSpan="4" style={{textAlign: "center"}}>
                                <video width="300em" controls src={process.env.PUBLIC_URL+`/video/${mcsData.ctt_video_file_name}`}></video>
                            </td>
                        </tr>}
                    </tbody>,
                    <McsImage 
                        key="2" 
                        mcsid={this.props.mcsid}
                        moreinfo={this.moreInfoFunc}
                    />
                ]
            case "diagvar":
                return (
                    <DiagvarInfo
                        diagvarid={this.state.infoid}
                        closemcsinfo={this.mcsInfo}
                    />
                )
            case "tornado":
                return (
                    <TornadoInfo 
                        tornadoid={this.state.infoid}
                        closetornadoinfo={this.mcsInfo}
                    />  
                )
            case "windthrow":
                return (
                    <WindthrowInfo 
                        windthrowid={this.state.infoid}
                        closewindthrowinfo={this.mcsInfo}
                    />  
                )
            default:       
        }    
    }
}

const mapStateToProps = (state) => {
    return{
        msdatamap: state.msdatamap.meteojsondata,
        msdataload: state.msdatamap.meteoloaddata,
        lang: state.msdatamap.lang
    }
  };
  
  const mapDispatchToProps = () => {
    return {
        togglemsdata,
        visiblemsdata
    }
  };
  
export default connect(mapStateToProps, mapDispatchToProps())(McsInfo);