import React from 'react';
import EventList from './Eventlist';
import './Map.css';
import {connect} from 'react-redux';
import { Button } from 'react-bootstrap';

class InfoWind extends React.Component {

    render() {
        return (
            <div className='infoWrapper'>
                <Button onClick={() => this.props.clickopenwind(false)}>{this.props.lang === 'ru' ? "Закрыть": "Close"}</Button>
                <EventList 
                    osmid={this.props.text.osm_id}
                    name_en={this.props.text.name_en}
                    name_ru={this.props.text.name}
                    filter={this.props.filter}
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return{
        lang: state.msdatamap.lang
    }
};
  
export default connect(mapStateToProps)(InfoWind);