import React from 'react';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {togglemsdata, visiblemsdata} from './reduxToolkit/Reducers';
import {connect} from 'react-redux'


class MeteostationInfo extends React.Component{    

    constructor(props) {
        super(props);
        this.state = {
          info: []
        }
    }

    componentDidMount(){
        this.getMSData();
    }

    getMSData(){
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:weather_stations&outputFormat=application/json&CQL_FILTER=wmoid_icao=${this.props.msid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:weather_stations&outputFormat=application/json&CQL_FILTER=wmoid_icao=${this.props.msid}`,
            success: (data) => {
                this.setState({info: data.features[0].properties});
                this.props.togglemsdata(data.features);
                this.props.visiblemsdata(true);
            }
        })
    }

    render(){
        let msdata = this.state.info;
        return(
            <tbody>
                <tr><td colSpan="2" style={{padding: '0', borderTopColor: 'white', borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'white'}}><Button onClick={() => this.props.closemsinfo(false)}>{this.props.lang === 'ru' ? "Назад": "Back"}</Button></td></tr>
                <tr><td colSpan="2"><b>{this.props.lang === 'ru' ? "Информация о метеостанции:" : "Information about meteostation:"}</b></td></tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Название" : "Name"}</td>
                    <td>{this.props.lang === 'ru' ? msdata.name_ru : msdata.name_en}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Идентификатор ВМО" : "WMOID"}</td>
                    <td>{msdata.wmoid_icao}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Абсолютная высота, м" : "Altitude, meters"}</td>
                    <td>{msdata.height}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Широта, град. с.ш." : "Latitude, degrees north"}</td>
                    <td>{Math.floor(msdata.y * 100) / 100}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Долгота, град. в.д." : "Longitude, degrees east"}</td>
                    <td>{Math.floor(msdata.x * 100) / 100}</td>
                </tr>
            </tbody>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        msdatamap: state.msdatamap.meteojsondata,
        msdataload: state.msdatamap.meteoloaddata,
        lang: state.msdatamap.lang
    }
};

const mapDispatchToProps = () => {
    return {
        togglemsdata,
        visiblemsdata
    }
};
export default connect(mapStateToProps, mapDispatchToProps())(MeteostationInfo);