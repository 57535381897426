import React from 'react';
import $ from 'jquery';
import { Button } from 'react-bootstrap';
import {togglemsdata, visiblemsdata} from './reduxToolkit/Reducers';
import {connect} from 'react-redux'

class TornadoInfo extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          info: []
        }
    }

    componentDidMount(){
        this.getTornadoData();
    }

    getTornadoData(){
        $.ajax({
            //url: `http://ogs.psu.ru:8080/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:tornadoes_cfo_2001_2016&outputFormat=application/json&CQL_FILTER=Tornado_ID=${this.props.tornadoid}`,
            url: `https://api.gispsu.ru/geoserver/conv_events/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=conv_events:tornadoes_cfo_2001_2016&outputFormat=application/json&CQL_FILTER=Tornado_ID=${this.props.tornadoid}`,
            success: (data) => {
                this.setState({info: data.features[0].properties});
                this.props.togglemsdata(data.features);
                this.props.visiblemsdata(true);
            }
        })
    }

    render(){
        let tornadodata = this.state.info;
        return(
            <tbody>
                <tr><td colSpan="2" style={{padding: '0', borderTopColor: 'white', borderLeftColor: 'white', borderRightColor: 'white', backgroundColor: 'white'}}><Button onClick={() => this.props.closetornadoinfo(false)}>{this.props.lang === 'ru' ? "Назад": "Back"}</Button></td></tr>
                <tr><td colSpan="2" style={{borderBottomColor: 'white'}}><b>{this.props.lang === 'ru' ? "Информация о смерче:": "Information about tornado:"}</b></td></tr>
                <tr><td colSpan="2" style={{borderTopColor: 'white'}}><i>{this.props.lang === 'ru' ? "Информация из базы данных смерчей в Северной Евразии ": "Data from database of tornadoes in Northen Eurasia "}<a href="https://journals.ametsoc.org/view/journals/mwre/148/8/mwrD190251.xml?redirectedFrom=fulltext&tab_body=abstract-display" target="_blank" rel="noopener noreferrer">{this.props.lang === 'ru' ? "(ссылка)": "(link)"}</a></i></td></tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Дата и время (ВСВ)": "Date and time (UTC)"}</td>
                    <td>{tornadodata.Date_UTC_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Точность определения времени" : "Time accuracy"}</td>
                    <td>{tornadodata.Time_accuracy_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Качество данных" : "Data quality"}</td>
                    <td>{tornadodata.Event_Certainty}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Субъект РФ" : "Region"}</td>
                    <td>{tornadodata.Region}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Район" : "District"}</td>
                    <td>{tornadodata.District}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Населенный пункт" : "Settlement"}</td>
                    <td>{tornadodata.Place}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Широта начальной точки, град. с.ш." : "Lattitude start, degrees north"}</td>
                    <td>{tornadodata.Lat_start_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Долгота начальной точки, град. с.ш." : "Longitude start, degrees east"}</td>
                    <td>{tornadodata.Lon__start_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Широта конечной точки, град. с.ш." : "Lattitude end, degrees north"}</td>
                    <td>{tornadodata.Lat__end_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Долгота конечной точки, град. с.ш." : "Longitude end, degrees east"}</td>
                    <td>{tornadodata.Lon__end_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Тип поверхности" : "Type of tornado surface"}</td>
                    <td>{tornadodata.Type_of_tornado__surface_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Интенсивность по шкале Фуджита" : "Intensity (Fujita scale)"}</td>
                    <td>{tornadodata.Fujita_Scale}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Продолжительность" : "Duration"}</td>
                    <td>{tornadodata.Duration__min_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Длина пути, км" : "Path length, km"}</td>
                    <td>{tornadodata.Path_Length__km_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Средняя ширина пути, м" : "Average diameter, m"}</td>
                    <td>{tornadodata.Diameter_Average__m_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Максимальная ширина пути, м" : "Maximum diameter, m"}</td>
                    <td>{tornadodata.Diameter_max__m_}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Направление движения": "Movement direction"}</td>
                    <td>{tornadodata.Movement_direction}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Погибшие, чел." : "Dead"}</td>
                    <td>{tornadodata.Dead}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Пострадавшие, чел." : "Injured"}</td>
                    <td>{tornadodata.Injured}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Описание явления" : "Event description"}</td>
                    <td>{tornadodata.Event_and_source_description}</td>
                </tr>
                <tr> 
                    <td>{this.props.lang === 'ru' ? "Источник данных" : "Source report"}</td>
                    <td>{tornadodata.Source}</td>
                </tr>
                {/* <tr> 
                    <td>External URL</td>
                    {tornadodata.External_URL!==null && <a href={tornadodata.External_URL} target="_blank" rel="noopener noreferrer">link</a>}
                </tr> */}
            </tbody>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        msdatamap: state.msdatamap.meteojsondata,
        msdataload: state.msdatamap.meteoloaddata,
        lang: state.msdatamap.lang
    }
};

const mapDispatchToProps = () => {
    return {
        togglemsdata,
        visiblemsdata
    }
};
export default connect(mapStateToProps, mapDispatchToProps())(TornadoInfo);